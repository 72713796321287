import React,{useContext, useState} from 'react';
import usePersistedState from '../customHooks/usePersistedState';



export const ThemeContext = React.createContext();



const ThemeContextProvider = ({children})=>{

    const [theme, setTheme] = usePersistedState("theme",'light');
    const [clicks, setClicks] = usePersistedState("toggle-light",10);

    return <ThemeContext.Provider value = {{theme, setTheme,setClicks,clicks}}>
        {children}
    </ThemeContext.Provider>
}

export default ThemeContextProvider;